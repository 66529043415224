<template>
  <v-dialog v-model="dialog" persistent max-width="900px" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">{{ companyType ? 'Update' : 'Create' }} Organisation Type</span>
      </v-card-title>
      <v-card-text>
        <Form ref="form" v-model="valid" lazy-validation @submit="create" onSubmit="return false;">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="name" label="Name*" :rules="nameRules" required dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <autocomplete v-model="parent" :items="companyTypesFiltered" item-text="name" item-value="id" label="Parent" dense hide-details="auto"></autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </Form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox v-model="createAnother" label="Create Another" dense />
        <v-btn class="ml-3 px-5" @click="cancel">Cancel</v-btn>
        <v-btn class="ml-3 px-5" color="primary" @click="create">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { rules } from '@/helpers/validation';

export default {
  name: 'CreateCompanyType',
  props: {
    companyType: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      name: '',
      parent: null,
      createAnother: false,
      valid: false,
      nameRules: [rules.required, rules.moreThanTwo],
    };
  },
  created() {
    if (this.companyType) {
      this.name = this.companyType.name;
      this.parent = this.companyType.parent;
    }
  },
  computed: {
    ...mapState(['formDirty', 'companyTypes']),
    companyTypesFiltered() {
      return this.companyTypes.filter((type) => !type.parent && type.name !== 'Other');
    },
  },
  methods: {
    ...mapActions(['createCompanyType', 'updateCompanyType']),
    async create() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.companyType) {
        const record = await this.updateCompanyType({ id: this.companyType.id, name: this.name, parent: this.parent });
        if (!record) {
          return;
        }
      } else {
        const record = await this.createCompanyType({ name: this.name, parent: this.parent });
        if (!record) {
          return;
        }
      }

      this.name = '';

      if (!this.createAnother) {
        this.close();
      }
      this.$refs.form.reset();
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
