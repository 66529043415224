<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Organisation Types</span>
        <v-spacer></v-spacer>
        <div class="max-w-4xl pr-3">
          <v-text-field v-model="searchText" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </div>
        <v-btn class="mt-3" color="primary" @click="openCreateModal">
          <v-icon>mdi-plus</v-icon>
          <span class="ml-2">Create</span>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="companyTypeList" :search="searchText" sort-by="calories">
        <template v-slot:item.actions="{ item }">
          <v-icon small :disabled="['Funder', 'Startup & Scale-up'].includes(item.name)" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon :disabled="item.companyCount > 0" small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateCompanyType from '@/components/admin/CreateCompanyType.vue';

export default {
  name: 'CompanyTypes',
  data: () => ({
    headers: [
      { text: '#', value: 'index', width: '30px' },
      { text: 'Organisation Type', value: 'name' },
      { text: 'Parent', value: 'parentName' },
      { text: 'Count', value: 'companyCount' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px', align: 'end' },
    ],
    searchText: '',
  }),
  computed: {
    ...mapState(['companyTypes']),
    companyTypeList() {
      return this.companyTypes.map((type, i) => ({
        ...type,
        index: i + 1,
        parentName: this.companyTypes.find((t) => t.id === type.parent)?.name || '',
        companyCount: parseInt(type?.companycount1 || '0') + parseInt(type?.companycount2 || '0'),
      }));
    },
  },
  created() {
    this.fetchCompanyTypes();
  },
  methods: {
    ...mapActions(['fetchCompanyTypes', 'deleteCompanyType']),
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteCompanyType(item.id);
          modal.close();
        },
      });
    },
    openCreateModal() {
      this.$showModal(CreateCompanyType);
    },
    editItem(item) {
      this.$showModal(CreateCompanyType, { companyType: item });
    },
  },
};
</script>
